<template>
  <div class="sendKinsCard">
    <div class="sendKins-cont">
      <transition name="van-slide-left">
        <div class="one-page" v-if="active == 0">
          <divider>亲情号享受</divider>
          <div class="top">
            <div class="one-pageTq" v-for="(item, index ) in enjoy" :key="index">
              <div class="one-pageImg">
                <img :src="item.url" alt="">
              </div>
              <div class="hint-title">{{item.name}}</div>
            </div>
          </div>
          <Button type="primary" block @click="active = 1">开通亲属卡</Button>
        </div>
        <div class="two-page" v-if="active == 1">
          <divider>选择家人开通</divider>
          <grid :column-num="2" :gutter="20">
            <grid-item
              icon="photo-o"
              :text="item.title"
              v-for="(item, index) in familyData"
              :key="index"
              @click="selectRelation(item,index)"
            >
              <span slot="icon" class="iconfont" :class="item.icon"></span>
            </grid-item>
          </grid>
        </div>
        <div class="three-page" v-if="active == 2">
          <divider>{{page_title}}</divider>
          <cell-group>
            <field v-model="phone" type="tel" label="手机号" placeholder="请输入手机号" />
            <field v-model="quota" type="number" label="亲属卡额度" placeholder="请输入发卡额度" />
            <field v-model="card_name" clickable @click="showCardList = true" readonly label="绑定消费卡" placeholder="请点击选择">
              <!-- <template slot="button">
                <Button size="mini" type="primary" @click="showCardList = true">选择</Button>
              </template> -->
            </field>
          </cell-group>
          <div class="btn_group">
            <Button class="btn" type="info" @click="active = 1">重新选择</Button>
            <Button class="btn" type="primary" @click="binding">绑定家人</Button>
          </div>
        </div>
      </transition>
    </div>
    <popup v-model="showCardList" position="bottom">
      <picker show-toolbar :columns="columns" @cancel="showCardList = false" @confirm="onConfirm" />
    </popup>
  </div>
</template>

<script>
import Creditshop from "@/api/creditshop";
import {
  Divider,
  Button,
  Grid,
  GridItem,
  Field,
  CellGroup,
  Popup,
  Picker
} from "vant";
export default {
  components: {
    Divider,
    Button,
    Grid,
    GridItem,
    Field,
    CellGroup,
    Popup,
    Picker
  },
  data() {
    return {
      enjoy: [
        {
          icon: "iconfont iconchuzhi",
          name: "储值共享",
           url: require("../../../../public/static/images/vip/chuzigongxiang.jpg")
        },
        {
          icon: "iconfont iconicon_erweima", 
          name: "扫码买单",
          url: require("../../../../public/static/images/vip/saomamaidan.jpg")
        },
        {
          icon: "iconfont iconjifen3",
          name: "积分归集",
          url: require("../../../../public/static/images/vip/jifenguiji.jpg")
        }
      ], // 享受
      phone: "",
      quota: "",
      card_name: "",
      account_id: "", // 账户id
      showCardList: false,
      columns: [],
      CardList: [],
      active: 0,
      familyData: [
        {
          title: "父母",
          icon: "iconfumu"
        },
        {
          title: "伴侣",
          icon: "iconqinglv"
        },
        {
          title: "子女",
          icon: "iconzinv"
        },
        {
          title: "其他人员",
          icon: "iconqitarenyuan"
        }
      ],
      page_title: "",
      relation: "", // 发卡关系
      account_type: "" //账户类型
    };
  },
  watch: {
    active(val) {
      if (val == 1) {
        this.phone = "";
        this.card_name = "";
        this.account_id = "";
        this.quota = "";
      }
    }
  },
  mounted() {
    this.familyaccount();
  },
  methods: {
    async binding() {
      try {
        const res = await Creditshop.openFamily_card(this.relation,this.phone,this.account_type,this.account_id,this.quota);
        if (res.code == 200) {
          this.$toast({
            message: res.msg,
            duration: 1000
          });
          this.$router.replace("/kinsfolkCardList");
        } else {
          this.$toast({
            message: res.msg,
            duration: 1000
          })
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    onConfirm(name, idx) {
      console.log(name, idx);
      this.card_name = name;
      this.account_id = this.CardList[idx].account_id
      this.account_type = this.CardList[idx].account_type;
      this.showCardList = false;
    },
    selectRelation(item, index) {
      this.active = 2;
      this.page_title = item.title;
      this.relation = index + 1;
    },
    // 
    async familyaccount() {
      try {
        const res = await Creditshop.familyaccount();
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i];
            this.columns.push(`${element.account_name}(${element.balance}元)`)
          }
          this.CardList = res.data;
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sendKinsCard {
  height: 100%;
  background-image: url("../../../../public/static/images/vip/familyBg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 367px;
  display: flex;

  .sendKins-cont {
    width: 100%;
    background: #fff;
    flex: 1;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
  }

  .one-page {
    padding: 0 12px;

    .top {
      display: flex;
      margin-bottom: 30px;
    }
    .one-pageImg {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 1px solid #999;
      margin-bottom: 10px;
      text-align: center;
      line-height: 90px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .one-pageTq {
      flex: 1;
      padding: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .two-page {
    .iconfont {
      font-size: 24px;
    }
  }

  .three-page {
    .btn_group {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      .btn {
        width: 44%;
      }
    }
  }
}
</style>